.sections a {
  color: #fa2244;
  font-weight: bold;
  text-decoration: none;
}

.sections a:hover {
  font-weight: bold;
  text-decoration: underline;
}

.section {
  padding: 60px 24px;
  text-align: left;
}
.section__container {
  max-width: 800px;
  margin: 0 auto;
}
.section--slanted {
  padding: 40px;
  background: #212121;
  color: #fff;
  position: relative;
  z-index: 1;
}

.section--slanted::after {
  background: inherit;
  bottom: 0;
  content: "";
  display: block;
  height: 80%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
}

.section--what-we-do {
  background: #fff;
  color: #212121;
  z-index: 9;
}
.section--who-we-are {
  background: #fa2244;
  z-index: 8;
}
.section--who-we-are a {
  color: #212121;
}
.section--contact {
  background: hsl(0, 0%, 13%);
  z-index: 7;
}
.section--company-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #212121;
  color: #fff;
  font-size: 0.625rem;
}

.section--company-info p {
  padding: 0;
  margin: 0;
}

@media