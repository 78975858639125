/* 
$rich-black: rgba(14, 0, 4, 1);
$dark-sienna: rgba(49, 8, 31, 1);
$pune: rgba(107, 15, 26, 1);
$red-violet: rgba(185, 19, 114, 1);
$:flurescent-pink: rgba(250, 25, 139, 1);

light pink #FC80AB
deep pink #FA2244
dark grey #212121
white #FFFFFF

*/

body {
  /* overflow:hidden; */
}

* {
  font-family: "Space Mono", monospace;
}

html {
  color: #fc80ab;
  text-align: center;
  font-family: "Space Mono", monospace;
}

.app {
  /* height: 100vh; */
}



.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
