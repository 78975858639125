.header {
  background: #212121;
  color: #fff;
  position: relative;
  z-index: 10;
}
.header:after {
  background: inherit;
  bottom: 0;
  content: "";
  display: block;
  height: 80%;
  left: 0;
  position: absolute;
  right: 0;
  /* transform: skewY(-3deg); */
  /* transform-origin: 100%; */
  z-index: -1;
}
.header h1 {
  line-height: 1rem;
  margin: 1rem 0 0;
  font-family: "Lacquer", sans-serif;
}
.header__tagline {
  font-size: 0.875rem;
  color: #FA2244;
}
.header__logo {
  height: 400px;
}
